import { yupResolver } from '@hookform/resolvers/yup';
import { iStore } from 'domain/interfaces/models';
import { makeRemoteCreateSacConfig } from 'main/factories/usecases/sacConfig/CreateSacConfig';
import { makeRemoteGetSacConfig } from 'main/factories/usecases/sacConfig/GetSacConfig';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { createSacConfigSchema } from 'validation/sac/createConfig';
import { DaysAbbreviation, DaysOfWeek } from './types';

export const daysMap: Record<DaysAbbreviation, DaysOfWeek> = {
  SU: {
    label: 'SU',
    fullLabel: 'Domingo',
    value: 0,
    abbreviate: 'Dom',
  },
  MO: {
    label: 'MO',
    fullLabel: 'Segunda',
    value: 1,
    abbreviate: 'Seg',
  },
  TU: {
    label: 'TU',
    fullLabel: 'Terça',
    value: 2,
    abbreviate: 'Ter',
  },
  WE: {
    label: 'WE',
    fullLabel: 'Quarta',
    value: 3,
    abbreviate: 'Qua',
  },
  TH: {
    label: 'TH',
    fullLabel: 'Quinta',
    value: 4,
    abbreviate: 'Qui',
  },
  FR: {
    label: 'FR',
    fullLabel: 'Sexta',
    value: 5,
    abbreviate: 'Sex',
  },
  ST: {
    label: 'ST',
    fullLabel: 'Sábado',
    value: 6,
    abbreviate: 'Sáb',
  },
};

export const daysOfWeek: DaysOfWeek[] = [
  {
    label: 'SU',
    fullLabel: 'Domingo',
    value: 0,
    abbreviate: 'Dom',
  },
  {
    label: 'MO',
    fullLabel: 'Segunda',
    value: 1,
    abbreviate: 'Seg',
  },
  {
    label: 'TU',
    fullLabel: 'Terça',
    value: 2,
    abbreviate: 'Ter',
  },
  {
    label: 'WE',
    fullLabel: 'Quarta',
    value: 3,
    abbreviate: 'Qua',
  },
  {
    label: 'TH',
    fullLabel: 'Quinta',
    value: 4,
    abbreviate: 'Qui',
  },
  {
    label: 'FR',
    fullLabel: 'Sexta',
    value: 5,
    abbreviate: 'Sex',
  },
  {
    label: 'ST',
    fullLabel: 'Sábado',
    value: 6,
    abbreviate: 'Sáb',
  },
];

interface ConfigContextValue {
  scheduleDays: {
    day: string;
    hours: { start: string; finish: string }[];
  }[];
  scheduleType: 'FIXED' | 'FLEXIBLE' | undefined;
  loading: boolean;
  removePhone: (index: number) => void;
  submitValues: () => void;
  setPhoneNumber: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  createNewPhone: () => void;
  removeHour: (day: DaysAbbreviation, index: number) => void;
  handleSelectDay: (day: DaysOfWeek) => void;
  handleScheduleType: (type: 'FIXED' | 'FLEXIBLE') => void;
  createNewHour: (day: DaysAbbreviation) => void;
  setStartFinish: (
    value: string,
    day: DaysAbbreviation,
    field: 'start' | 'finish',
    indexHours?: number,
  ) => void;
}

const ConfigContext = createContext<ConfigContextValue | undefined>(undefined);

export function useSacConfig(): ConfigContextValue {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}

export const SacConfigProvider: React.FC = ({ children }) => {
  const [hasAlreadyConfig, setHasAlreadyConfig] = useState(false);

  const [loading, setLoading] = useState(false);

  const { user } = useSelector((store: iStore) => store.auth);

  const form = useForm({
    resolver: yupResolver(createSacConfigSchema),
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      phones: [''],
      supportEmail: '',
      scheduleType: 'FIXED',
      schedule: [],
    },
  });

  const { watch, setValue, getValues, trigger, unregister } = form;

  const scheduleDays = watch('schedule') ?? [];
  const scheduleType = watch('scheduleType');

  const { id } = useParams<{ id: string }>();

  const getSacConfig = useCallback(async () => {
    const getRemoteSacConfig = makeRemoteGetSacConfig();
    setLoading(true);
    try {
      const response = await getRemoteSacConfig.get({
        orgId: String(id) ?? '1',
      });
      if (response) {
        setHasAlreadyConfig(true);

        setValue('schedule', response.config.schedule);
        setValue('supportEmail', response.config.supportEmail);
        setValue('phones', response.config.phones);

        setValue('scheduleType', response.config.scheduleType);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setValue, user]);

  const handleSelectDay = (day: DaysOfWeek) => {
    const isSelected = scheduleDays.some(
      selected => selected.day === day.label,
    );

    trigger('schedule');

    if (isSelected) {
      setValue(
        'schedule',
        scheduleDays.filter(schedule => schedule.day !== day.label),
      );
    } else {
      setValue('schedule', [
        ...scheduleDays,
        {
          day: day.label,
          hours: [
            {
              start:
                scheduleType === 'FIXED'
                  ? scheduleDays?.[0]?.hours?.[0]?.start
                  : '',
              finish:
                scheduleType === 'FIXED'
                  ? scheduleDays?.[0]?.hours?.[0]?.finish
                  : '',
            },
          ],
        },
      ]);
    }
  };

  const setStartFinish = (
    value: string,
    day: DaysAbbreviation,
    field: 'start' | 'finish',
    indexHours = 0,
  ) => {
    if (scheduleType === 'FIXED') {
      const newSchedule = scheduleDays?.map(schedule => {
        schedule.hours[indexHours] = {
          ...schedule.hours[indexHours],
          [field]: value,
        };
        return schedule;
      });

      setValue('schedule', newSchedule);
      return;
    }

    const newSchedule = scheduleDays?.map(schedule => {
      if (schedule.day === day) {
        schedule.hours[indexHours] = {
          ...schedule.hours[indexHours],
          [field]: value,
        };
      }
      return schedule;
    });

    setValue('schedule', newSchedule);
  };

  const setPhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const phones = getValues('phones') ?? [];
    phones[index] = e.target.value;
    setValue('phones', phones);
  };

  const createNewPhone = () => {
    const newPhones = getValues('phones') ?? [];
    newPhones.push('');
    setValue('phones', newPhones);
  };

  const removePhone = (index: number) => {
    console.log('phones', getValues('phones'));
    unregister(`phones.${index}`);
  };

  const createNewHour = (day: DaysAbbreviation) => {
    const newSchedule = scheduleDays?.map(schedule => {
      if (schedule.day === day || scheduleType === 'FIXED') {
        schedule.hours.push({ start: '', finish: '' });
      }
      return schedule;
    });

    setValue('schedule', newSchedule);
  };

  const removeHour = (day: DaysAbbreviation, index: number) => {
    const newSchedule = scheduleDays?.map(schedule => {
      if (schedule.day === day || scheduleType === 'FIXED') {
        schedule.hours.splice(index, 1);
      }
      return schedule;
    });

    setValue('schedule', newSchedule);
  };

  const addCodeCountry = (phone: string): string => {
    const valueFormatted = phone.replace(/\D/g, '');
    if (!valueFormatted.startsWith('55')) {
      return `+55${valueFormatted}`;
    }
    if (valueFormatted.startsWith('55')) {
      return `+${valueFormatted}`;
    }
    return valueFormatted;
  };

  const handleScheduleType = (type: 'FIXED' | 'FLEXIBLE') => {
    const newSchedule = scheduleDays.map(schedule => ({
      ...schedule,
      hours: [
        {
          start: hasAlreadyConfig ? scheduleDays?.[0]?.hours?.[0]?.start : '',
          finish: hasAlreadyConfig ? scheduleDays?.[0]?.hours?.[0]?.finish : '',
        },
      ],
    }));
    setValue('schedule', newSchedule);
    setValue('scheduleType', type);
  };

  const submitValues = async () => {
    const values = getValues();
    const createSacConfig = makeRemoteCreateSacConfig();

    try {
      await createSacConfig.create({
        orgId: String(user?.org.id) ?? '1',
        body: {
          ...values,
          phones: values.phones?.map(addCodeCountry),
        },
      });
      toast.success('Configurações salvas com sucesso');
    } catch (error) {
      console.error(error);
    }
  };

  const configValue: ConfigContextValue = {
    loading,
    scheduleType,
    scheduleDays,
    submitValues,
    removePhone,
    createNewPhone,
    setPhoneNumber,
    removeHour,
    createNewHour,
    handleSelectDay,
    handleScheduleType,
    setStartFinish,
  };

  useEffect(() => {
    getSacConfig();
  }, [getSacConfig]);

  return (
    <FormProvider {...form}>
      <ConfigContext.Provider value={configValue}>
        {children}
      </ConfigContext.Provider>
    </FormProvider>
  );
};
